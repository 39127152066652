import React, { Fragment } from 'react'
import Facts from '../../components/Facts'
import Team from '../../components/Team'
import { Four, One, Row, RowGroup, Three, Two } from '../../components/Grid'
import Image from '../../components/Image'
import OtherProjects from '../../components/OtherProjects'
import ProjectsContext from '../../components/ProjectsContext'
import SEO from '../../components/SEO'
import { ProjectName } from '../../components/Style'
import Link from '../../components/Link'
import ContactForm from '../../components/ContactForm'
import Layout from '../../components/Layout'

const breadcrumbs = `{
  "@context": "http://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "item": {
      "@id": "https://500tech.com/projects",
      "name": "Projects"
    }
  },{
    "@type": "ListItem",
    "position": 2,
    "item": {
      "@id": "https://500tech.com/projects/phytech",
      "name": "Phytech"
    }
  }]
}`

export default () => {
  return (
    <Layout>
    <ProjectsContext.Consumer clientName="Mimic">
      {({ allProjects, currentProject }) => (
        <Fragment>
          <SEO
            path="/projects/mimic"
            title="Mimic 2 by 500Tech"
            description="Mimic is our open-source tool for mocking server responses in a browserv."
            ld={breadcrumbs}
          />

          <ProjectName>{currentProject.projectName}</ProjectName>
          <Facts {...currentProject} />

          <RowGroup>
            <Row>
              <Three>
                <p>
                  Mimic is our open-source dev tool for mocking server responses
                  in a browser.
                </p>
                <p>
                  Front end and back end are often developed separately. While
                  the back end is not working, the front-end developer is very
                  limited in what they can do. But with Mimic, they just mock
                  server responses right from within the browser and develop
                  as if the server was alive.
                </p>
                <p>
                  <Link to="http://github.com/500tech/mimic" white>
                    Mimic on GitHub
                  </Link>
                </p>
              </Three>
            </Row>

            <Row>
              <Four>
                <Image path="projects/mimic/mimic-1.png" />
              </Four>
            </Row>
          </RowGroup>

          <Team team={currentProject.team} />

          <RowGroup>
            <Row>
              <Three>
                <p>
                  Let’s say the server part is not implemented yet, so when the
                  client side makes a request, an error occurs. The Mimic shows
                  this in the browser’s status bar:
                </p>
              </Three>
            </Row>

            <Row>
              <Four>
                <Image path="projects/mimic/mimic-qe-404.png" />
              </Four>
            </Row>
          </RowGroup>

          <RowGroup>
            <Row>
              <Three>
                <p>
                  The developer changes the server response to what they need:
                </p>
              </Three>
            </Row>

            <Row>
              <Four>
                <Image path="projects/mimic/mimic-qe-edit.png" />
              </Four>
            </Row>
          </RowGroup>

          <RowGroup>
            <Row>
              <Three>
                <p>
                  Now whenever a page makes this request, Mimic intercepts it
                  and returns a mocked response (shown in the status bar in
                  blue):
                </p>
              </Three>
            </Row>

            <Row>
              <Four>
                <Image path="projects/mimic/mimic-qe-mocked.png" />
              </Four>
            </Row>
          </RowGroup>

          <RowGroup>
            <Row>
              <Three>
                <p>
                  Depending on what you develop and test for, you may need to
                  set up particular HTTP headers, add a timeout, or even
                  simulate a response with a file.
                </p>
                <p>That’s what the full-featured mocks editor is for:</p>
              </Three>
            </Row>

            <Row>
              <Four>
                <Image path="projects/mimic/mimic-fe-2.png" />
              </Four>
            </Row>
          </RowGroup>

          <Row>
            <One style={{ paddingTop: '5.2%' }}>
              Mocked requests can be enabled and disabled individually
              or as groups. Manage the large list with cut, copy and paste.
            </One>
            <Three>
              <Image path="projects/mimic/mimic-fe-3.png" />
            </Three>
          </Row>

          <Row>
            <Three>
              <Image path="projects/mimic/mimic-fe-4.png" />
            </Three>
            <One>
              A large application may send many requests to a server. It could
              be hard to catch the needed one in the status bar. That’s why
              Mimic has a log.
            </One>
          </Row>

          <Row>
            <One>
              Export and import your mocks configuration to share with
              colleagues.
            </One>
            <Three>
              <Image path="projects/mimic/mimic-ie.png" />
            </Three>
          </Row>

          <Row>
            <Two>
              <p>To install, add to your packages:</p>
              <p>
                <tt style={{ color: '#3b4' }}>npm install mimic --save</tt>
              </p>
            </Two>
            <Two>
              <p>Import into your application:</p>
              <p>
                <tt style={{ color: '#3b4' }}>import 'mimic';</tt>
              </p>
            </Two>
          </Row>

          <OtherProjects currentProject={currentProject} />
          <ContactForm />
        </Fragment>
      )}
    </ProjectsContext.Consumer>
    </Layout>
  )
}
